<template>
  <div class="eventManagement-form-box">
    <head-layout
      head-title="事故基本信息"
      :head-btn-options="headBtnOptions"
      @head-save="headSave(false)"
      @head-saveBack="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain">
        <div style="padding: 0 12px">
          <el-form
            ref="dataForm"
            :model="dataForm"
            label-width="150px"
            :disabled="formType == 'view'"
            :rules="rules"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="事故编号" prop="accCode">
                  <el-input
                    v-model="dataForm.accCode"
                    :disabled="
                      dataForm.isAutomatic || pageDisabled || dataForm.id
                    "
                    placeholder="请填写事故编号"
                  >
                    <template slot="append">
                      自动生成
                      <el-switch
                        :disabled="pageDisabled || dataForm.id"
                        v-model="dataForm.isAutomatic"
                        active-color="#13ce66"
                        @change="handleGetCode"
                      >
                      </el-switch>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="事故标题" prop="accTitle">
                  <el-input
                    v-model="dataForm.accTitle"
                    :title="dataForm.accTitle"
                    maxlength="100"
                    placeholder="请填写事故标题"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="发生地点" prop="address">
                  <el-input
                    v-model="dataForm.address"
                    :title="dataForm.address"
                    maxlength="60"
                    placeholder="请填写发生地点"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="报告人" prop="userName">
                  <el-input
                    v-model="dataForm.userName"
                    @focus="handleUserModeOpen()"
                    placeholder="请选择报告人"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="发生时间" prop="accTime">
                  <el-date-picker
                    type="datetime"
                    v-model="dataForm.accTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择报告时间"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="简要经过" prop="brifDesc">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="dataForm.brifDesc"
                    maxlength="225"
                    placeholder="请输入事故发生的简要经过和发生的原因"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="伤亡损失情况" prop="loss">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="dataForm.loss"
                    maxlength="225"
                    placeholder="请输入事故人员和财产损失的基本情况"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="事故处理措施" prop="treatment">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="dataForm.treatment"
                    maxlength="225"
                    placeholder="请输入事故发生后采取的处理措施及事故控制情况"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="现场情况" prop="treeData">
                  <el-upload
                    v-if="formType != 'view'"
                    accept=".jpg,.mp4,.png"
                    style="
                      margin-bottom: 12px;
                      display: flex;
                      align-items: center;
                    "
                    class="upload-demo"
                    action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km"
                    :show-file-list="false"
                    :before-upload="checkFileType"
                    :on-success="handleAvatarSuccess"
                    ref="uploadRef1"
                    :headers="headers"
                  >
                    <el-button size="small" type="primary" icon="el-icon-upload"
                      >点击上传
                    </el-button>
                  </el-upload>
                  <div class="content">
                    <div
                      v-for="(item, index) in treeData"
                      :key="item.id"
                      class="img-content"
                      @click="getNodeClick(item)"
                    >
                      <img :src="item.thumbnailLink" alt="" />
                      <i
                        v-if="formType !== 'view'"
                        class="el-icon-circle-close delete-icon"
                        @click.stop="handleRemove(item, index)"
                      ></i>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>

    <head-layout
      head-title="事故调查结果"
      v-if="dataForm.id && dataForm.accStatus !== 'PREPARE'"
    ></head-layout>
    <div
      class="formContentBox"
      v-if="dataForm.id && dataForm.accStatus !== 'PREPARE'"
    >
      <div class="formMain">
        <div style="padding: 0 12px">
          <el-form
            ref="dataForm1"
            :model="dataForm"
            label-width="150px"
            :disabled="formType == 'view'"
            :rules="rules"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="事故类别" prop="accType">
                  <avue-select
                    :props="{
                      label: 'dictValue',
                      value: 'dictKey',
                    }"
                    v-model="dataForm.accType"
                    placeholder="请选择事故类别"
                    type="tree"
                    :dic="ACC_TYPE"
                  ></avue-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="事故级别" prop="accLevel">
                  <div class="flex-container flex-align-c">
                    <avue-select
                      :props="{
                        label: 'dictValue',
                        value: 'dictKey',
                      }"
                      v-model="dataForm.accLevel"
                      placeholder="请选择事故级别"
                      type="tree"
                      :dic="acc_level"
                    ></avue-select>
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <template #content>
                        <span v-html="accLevelText"></span>
                      </template>
                      <i class="el-icon-question" style="font-size: 24px"></i>
                    </el-tooltip>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="直接经济损失" prop="lossFa">
                  <el-input
                    min="0"
                    type="number"
                    v-model="dataForm.lossFa"
                    :title="dataForm.lossFa"
                    placeholder="请输入直接经济损失评估结果"
                  >
                    <template slot="append">万元</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="间接经济损失" prop="lossIndirect">
                  <div class="flex-container flex-align-c">
                  <el-input-number
                    min="1"
                    style="width: 100%;"
                    :controls="false"
                    v-model="dataForm.lossIndirect"
                    placeholder="请输入间接经济损失评估结果"
                  >
                  </el-input-number>
                    <span class="lossIndirect">万元</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="工时损失" prop="lossTime">
                  <div class="flex-container flex-align-c">
                    <el-input-number
                      min="0"
                      style="width: 100%;"
                      :controls="false"
                      v-model="dataForm.lossTime"
                      :title="dataForm.lossTime"
                      placeholder="请输入工时损失"
                    >

                    </el-input-number>
                    <span class="lossIndirect">H</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="死亡人数" prop="lossDeath">
                  <el-input-number
                    style="width: 100%"
                    :min="0"
                    step-strictly
                    :step="1"
                    :max="9999"
                    v-model.number="dataForm.lossDeath"
                    type="number"
                    placeholder="请填写死亡人数"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="重伤人数" prop="lossSerious">
                  <el-input-number
                    style="width: 100%"
                    :min="0"
                    step-strictly
                    :step="1"
                    :max="9999"
                    type="number"
                    v-model.number="dataForm.lossSerious"
                    placeholder="请填写重伤人数"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="轻伤人数" prop="lossMinor">
                  <el-input-number
                    style="width: 100%"
                    :min="0"
                    step-strictly
                    :step="1"
                    :max="9999"
                    type="number"
                    v-model.number="dataForm.lossMinor"
                    placeholder="请填写轻伤人数"
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="其他损失" prop="lossOther">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="dataForm.lossOther"
                    maxlength="225"
                    placeholder="请输入其他损失描述信息"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="根本原因" prop="reason">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="dataForm.reason"
                    maxlength="225"
                    placeholder="请输入事故发生的根本原因"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="事故详细经过" prop="accDesc">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="dataForm.accDesc"
                    maxlength="225"
                    placeholder="请输入事故发生的详细经过和原因"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="责任认定及处理建议" prop="accResp">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="dataForm.accResp"
                    maxlength="225"
                    placeholder="请输入责任认定和处理建议"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="整改措施" prop="suggustTreatment">
                  <el-input
                    type="textarea"
                    :autosize="{ minRows: 2 }"
                    show-word-limit
                    v-model="dataForm.suggustTreatment"
                    maxlength="225"
                    placeholder="请输入建议的整改措施"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="调查报告" prop="treeData1">
                  <el-upload
                    :on-preview="handlePreview"
                    :disabled="formType == 'view'"
                    class="upload-demo"
                    accept=".rar,.zip,.doc,.docx,.pdf"
                    action="api/sinoma-resource/oss/endpoint/put-file"
                    :on-success="handleAvatarSuccess1"
                    :before-upload="checkFileType1"
                    :on-remove="handleRemove1"
                    :headers="headers"
                    multiple
                    :file-list="treeData1"
                  >
                    <el-button
                      v-if="formType != 'view'"
                      size="small"
                      type="primary"
                      icon="el-icon-upload"
                      >点击上传
                    </el-button>
                    <div
                      slot="tip"
                      class="el-upload__tip"
                      v-if="formType != 'view'"
                    >
                      支持扩展名：.rar .zip .doc .docx .pdf
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>

    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
      <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="selectData"
      ></UserDetpDialog>
    </el-dialog>
  </div>
</template>
<script>
import * as API from "@/api/eventManagement/index";
import { getToken } from "@/util/auth";
import website from "@/config/website";
import { dateFormat } from "@/util/date";
import { mapGetters } from "vuex";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import Template from "@/views/message/template/list.vue";
import { submit } from "@/api/eventManagement/index";
import snows from "@/util/snows";
export default {
  components: {
    Template,
    UserDetpDialog,
  },
  data() {
    return {
      type: "",
      accLevelText:
        "轻伤事故：指造成职工肢体伤残，或某些器官功能性或器质性轻度损伤，表现为劳动能力轻度或暂时丧失的伤害。\n" +
        "<br>一般指受伤职工歇工在一个工作日以上，但未达到重伤标准者重伤事故：指造成职工肢体残缺或视觉、听觉等器官受到严重损伤，一般能引起人体长期存在功能障碍，或劳动能力有重大损失的伤害，重伤失能损失等于和超过105个工作日；\n" +
        "<br>一般事故：是指造成3人以下死亡，或者10人以下重伤，或者1000万元以下直接经济损失的事故；\n" +
        "<br>较大事故：是指造成3人以上10人以下死亡，或者10人以上50人以下重伤，或者1000万元以上5000万元以下直接经济损失的事故；\n" +
        "<br>重大事故：是指造成10人以上30人以下死亡，或者50人以上100人以下重伤，或者5000万元以上1亿元以下直接经济损失的事故；\n" +
        "<br>特别重大事故：是指造成30人以上死亡，或者100人以上重伤（包括急性工业中毒，下同），或者1亿元以上直接经济损失的事故；\n" +
        "本款所称“以上”包括本数，“以下”不包括本数",
      showFileDialog: false,
      deptShow: false,
      treeData: [],
      treeData1: [],
      acc_level: [],
      ACC_TYPE: [],
      selectNode: {},
      formType: "",
      fileUrl: "",
      mapBox: false,
      dataForm: {
        accCode: "",
        accType: "",
        accLevel: "",
        isAutomatic: true,
        lossDeath: 0,
        lossSerious: 0,
        lossMinor: 0,
        lossIndirect: 0,
        lossTime: 0,
      },
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      rules: {
        accCode: [
          {
            required: true,
            message: "请填写事故编号",
            trigger: "blur",
          },
        ],
        accTitle: [
          {
            required: true,
            message: "请填写事故标题",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请填写发生地点",
            trigger: "blur",
          },
        ],
        accTime: [
          {
            required: true,
            message: "请选择报告时间",
            trigger: "change",
          },
        ],
        accType: [
          {
            required: true,
            message: "请选择事故类别",
            trigger: "change",
          },
        ],
        accLevel: [
          {
            required: true,
            message: "请选择事故级别",
            trigger: "change",
          },
        ],
        brifDesc: [
          {
            required: true,
            message: "请填写简要经过",
            trigger: "blur",
          },
        ],
        reason: [
          {
            required: true,
            message: "请输入事故发生的根本原因",
            trigger: "blur",
          },
        ],
      },
      selectionList: [],
    };
  },
  computed: {
    pageDisabled() {
      // 如果查看 或者 提交状态则禁用
      if (this.formType == "view" || this.dataForm.accStatus == "PREPARE") {
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters(["userInfo"]),
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.formType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        result.push({
          label: "提交并返回",
          emit: "head-saveBack",
          type: "button",
          btnOptType: "refer",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
  async mounted() {
    let type = this.$route.query.type;
    this.formType = type;
    if (type == "edit") {
      this.$loading();
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    } else if (type == "view") {
      this.$loading();
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    } else {
      this.$set(this.dataForm, "reportDeptId", this.userInfo.dept_id);
      this.$set(this.dataForm, "reportDeptName", this.userInfo.deptName);
      this.$set(this.dataForm, "userId", this.userInfo.user_id);
      this.$set(this.dataForm, "userName", this.userInfo.real_name);
    }
    await this.getCode("acc_level");
    await this.getCode("ACC_TYPE");
    await this.handleGetCode(true);
  },
  methods: {
    handleGetCode(val) {
      if (val) {
        API.getCode().then((res) => {
          this.dataForm.accCode = res.data.data;
        });
      } else {
        this.dataForm.accCode = "";
      }
    },
    async getCode(code) {
      await API.HD_SOURCE_TYPE(code).then((res) => {
        this[code] = res.data.data;
      });
    },
    selectData(row) {
      this.deptShow = false;
      this.dataForm.userId = row.id;
      this.dataForm.userName = row.realName;
      console.log("进行输出人员-------------------", row);
    },
    handleUserModeOpen() {
      this.deptShow = true;
    },
    handlePreview(file) {
      snows.downloadFile1(file.link, file.name);
    },
    checkFileType(file) {
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isJPG =
        fileType === ".jpg" || fileType == ".png" || fileType == ".mp4";
      if (!isJPG) {
        this.$message.warning("上传图片只能是 .jpg  .png .mp4 格式!");
      }
      if ((fileType === ".jpg" || fileType == ".png") && !isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      if (fileType == ".mp4" && !isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M && isLt5M;
    },
    checkFileType1(file) {
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      const isLt20M = file.size / 1024 / 1024 < 20;
      const fileTran = [".rar", ".zip", ".doc", ".docx", ".pdf"];
      const isJPG = fileTran.includes(fileType);

      if (!isJPG) {
        this.$message.warning("上传图片只能是 .rar  .zip .docx .pdf格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },
    handleAvatarSuccess(res, file, fileList) {
      this.treeData.push({
        extension: file.raw.type,
        id: file.response.data.attachId,
        link: file.response.data.link,
        thumbnailLink: file.response.data.thumbnailLink,
        name: file.response.data.originalName,
      });
      this.$set(this.dataForm, "treeData", this.treeData);
    },
    handleAvatarSuccess1(res, file, fileList) {
      this.treeData1.push({
        extension: file.raw.type,
        id: file.response.data.attachId,
        link: file.response.data.link,
        thumbnailLink: file.response.data.thumbnailLink,
        name: file.response.data.originalName,
      });
      this.$set(this.dataForm, "treeData1", this.treeData1);
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    async headSave(boolean) {
      let valid = await this.$refs.dataForm.validate().catch((err) => {
        return false;
      });
      if (!valid) return;
      if (this.$refs.dataForm1) {
        let valid1 = await this.$refs.dataForm1?.validate().catch((err) => {
          return false;
        });
        if (!valid1) return;
      }
      this.$loading();
      let accStatus = "";
      if (!boolean && !this.dataForm.id) {
        accStatus = "PREPARE";
      } else if (boolean && !this.dataForm.id) {
        accStatus = "APPROVED";
      } else if (!boolean && this.dataForm.accStatus == "PREPARE") {
        accStatus = "PREPARE";
      } else if (boolean && this.dataForm.accStatus == "PREPARE") {
        accStatus = "APPROVED";
      } else if (!boolean && this.dataForm.accStatus == "APPROVED") {
        accStatus = "APPROVED";
      } else if (boolean && this.dataForm.accStatus == "APPROVED") {
        accStatus = "FINISHED";
      }
      API.submit({
        ...this.dataForm,
        accStatus: accStatus,
        organizationId: this.userInfo.dept_id,
        organizationName: this.userInfo.deptName,
        siteSituation: JSON.stringify(this.treeData),
        reportDocUrl: JSON.stringify(this.treeData1),
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: !boolean ? "保存成功" : "提交成功",
              type: "success",
            });
            if (boolean) {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            } else {
              this.dataForm.id = res.data.data;
              this.getPrjbasicinfoDetail();
            }

            this.dataForm = {
              reportDate: dateFormat(new Date()),
            };
            this.$loading().close();
          }
        })
        .catch((err) => {
          this.$loading().close();
        });
    },
    // 根据ID查表单详情
    getPrjbasicinfoDetail() {
      API.detail({
        id: this.dataForm.id,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$loading().close();
            this.dataForm = res.data.data;
            if (res.data.data.siteSituation) {
              this.treeData = JSON.parse(res.data.data.siteSituation);
              this.$set(this.dataForm, "treeData", this.treeData);
            }
            if (res.data.data.reportDocUrl) {
              this.treeData1 = JSON.parse(res.data.data.reportDocUrl);
              this.$set(this.dataForm, "treeData1", this.treeData1);
            }
          }
        })
        .catch((err) => {
          this.$loading().close();
        });
    },
    getNodeClick(node) {
      this.selectNode = node;
      this.showFile(node);
    },
    handleRemove(item, index) {
      this.treeData.splice(index, 1);
    },
    handleRemove1(item, index) {
      this.treeData1.splice(index, 1);
    },
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" >
.eventManagement-form-box {
  background-color: #ffffff;
  height:  auto !important;
  .formContentBox {
    padding: 12px;
    height: calc(100% - 76px);
  }

  .formMain {
    width: 100%;
    overflow: auto;
    height: 100%;
  }
  .step-box {
    background-color: #ffffff;
    width: 125px;
  }

  .formTopic {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgb(187, 187, 187);
    font-weight: 600 !important;
    font-size: 18px;
  }

  .shuttleBackBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

    .el-tabs {
      width: 100%;
    }

    .table-box {
      width: calc(100% - 280px);
    }
  }
  .lossIndirect{
    display: inline-block;
    color: #909399;
    vertical-align: middle;
    position: relative;
    border-radius: 4px;
    padding: 0 10px;
    white-space: nowrap;
  }
  .shutleBack {
    width: 33%;
    background: #ffffff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
  }

  .leftRightBtn {
    margin: 211px 12px;
  }

  .shutleTitle {
    width: 100%;
    background-color: #f5f7fa;
    height: 40px;
    line-height: 40px;
    text-indent: 12px;
    border-bottom: 1px solid #ebeef5;
    font-size: 16px;
  }

  .shutContent {
    padding: 6px;
    height: 400px;
    overflow: auto;
  }

  .deptBox {
    line-height: 40px;
    color: #333333;
    border-bottom: 1px solid #ebeef5;
    font-size: 13px;
    display: flex;
  }

  .unitBox {
    width: 100%;
    display: flex;
    background-color: #f5f7fa;
    border-bottom: 1px solid #ebeef5;
  }

  .unitTitle {
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    font-size: 16px;
    cursor: pointer;
    border-right: 1px solid #ebeef5;
  }
  .content {
    display: flex;
    align-items: center;
    .img-content {
      width: 188px;
      height: 100px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;
      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
